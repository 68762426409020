<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import { initiaCvData, analyticsCategories } from "@/composables/const";
import { useAnalytics } from "@/composables/useAnalytics";
import { libraryEntryExist, createLibraryEntry, getLibraryEntriesCount } from "@/services/libraryService";

const { trackEvent } = useAnalytics();

const { t } = useI18n();
const router = useRouter();
const { user } = useAuth();

const upgradePremiumVisible = ref(false);
const modalAuthenticationVisible = ref(false);
const authenticationNavigate = ref("");

const createCV = async () => {
  if(user.value.authUser){
    const { result: cvExist } = await libraryEntryExist(user.value.authUser.uid);
    if (cvExist && user.value.details?.accountStatus === "Premium") {
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "footer_to_library_premium_user",
        value: 1,
      });
      router.push("/library");
    }
    else if(cvExist && user.value.details?.accountStatus !== "Premium"){
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "footer_to_library_free_user",
        value: 1,
      });
      router.push("/library");
    }
    else{
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "footer_to_create",
        value: 1,
      });
      const { result: cvData } = await createLibraryEntry(initiaCvData, user.value.authUser.uid);
      router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
    }

  }
  else{
    trackEvent("button_click", {
      category: analyticsCategories.CV_CREATION,
      action: "click",
      label: "footer_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "creation";
    modalAuthenticationVisible.value = true;
  }
};
const createCVExisting = async () => {
  if (user.value?.authUser) {
    const { result } = await getLibraryEntriesCount(user.value?.authUser?.uid);
    if(result?.count !== undefined){
      if (result?.count > 0 && user.value.details?.accountStatus !== "Premium") {
        trackEvent("button_click", {
          category: analyticsCategories.CV_EXISTING,
          action: "click",
          label: "footer_to_upgrade",
          value: 1,
        });
        upgradePremiumVisible.value = true;
      } else {
        if(result?.count < 10){
          trackEvent("button_click", {
            category: analyticsCategories.CV_EXISTING,
            action: "click",
            label: "footer_to_existing",
            value: 1,
          });
          router.push("/cv-existing");
        }
      }
    }
  }
  else {
    trackEvent("button_click", {
      category: analyticsCategories.CV_EXISTING,
      action: "click",
      label: "footer_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "existing";
    modalAuthenticationVisible.value = true;
  }

};

const evaluateCv = async() => {
  if(user.value.authUser){
    trackEvent("button_click", {
      category: analyticsCategories.CV_EVALUATION,
      action: "click",
      label: "footer_to_evaluate",
      value: 1,
    });
    router.push("/cv-evaluation");
  }
  else{
    trackEvent("button_click", {
      category: analyticsCategories.CV_EVALUATION,
      action: "click",
      label: "footer_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "evaluation";
    modalAuthenticationVisible.value = true;
  }
};

const screenSizeSmall = ref(false);

const checkScreenSize = () => {
  if (window.innerWidth < 600 || window.innerHeight < 400) {
    screenSizeSmall.value = true;
  }
  else{
    screenSizeSmall.value = false;
  }
};

const mainTitles = computed(() => screenSizeSmall.value ? [
  {
    name: t("footer.resumifyAI"),
    subTitles: [
      { text: t("footer.resumifyAIDescription"), action: null },
    ],
  },
  {
    name: t("footer.usefulLinks"),
    subTitles: [
      { text: t("footer.termsAndConditions"), action: () => router.push("/policies/terms-and-conditions") },
      { text: t("footer.imprint"), action: () => router.push("/policies/imprint") },
      { text: t("footer.privacyPolicy"), action: () => router.push("/policies/privacy-policy") },
    ],
  },
  {
    name: t("footer.contact"),
    subTitles: [
      { text: t("footer.ourAddress"), action: null },
      { text: "info@resumify-ai.com", action: () => window.location.href = "mailto:info@resumify-ai.com" },
    ],
  },
] : [
  {
    name: t("footer.resumifyAI"),
    subTitles: [
      { text: t("footer.resumifyAIDescription"), action: null },
    ],
  },
  {
    name: t("footer.services"),
    subTitles: [
      { text: t("footer.cvCreationScratch"), action: () =>  createCV() },
      { text: t("footer.cvCreationExisting"), action: () => createCVExisting() },
      { text: t("footer.cvEvaluation"), action: () => evaluateCv() },
    ],
  },
  {
    name: t("footer.usefulLinks"),
    subTitles: [
      { text: t("footer.termsAndConditions"), action: () => router.push("/policies/terms-and-conditions") },
      { text: t("footer.faq"), action: () => router.push("/faq") },
      { text: t("footer.imprint"), action: () => router.push("/policies/imprint") },
      { text: t("footer.privacyPolicy"), action: () => router.push("/policies/privacy-policy") },
    ],
  },
  {
    name: t("footer.contact"),
    subTitles: [
      { text: t("footer.ourAddress"), action: null },
      { text: "info@resumify-ai.com", action: () => window.location.href = "mailto:info@resumify-ai.com" },
    ],
  },
]);
const getHref = (text: string) => {
  switch (text) {
  case t("footer.cvCreationScratch"):
    return "/CvCreation";
  case t("footer.cvCreationExisting"):
    return "/library";
  case t("footer.cvEvaluation"):
    return "/CvEvalutation";
  case t("footer.termsAndConditions"):
    return "/policies/terms-and-conditions";
  case t("footer.imprint"):
    return "/policies/imprint";
  case t("footer.privacyPolicy"):
    return "/policies/privacy-policy";
  case t("footer.faq"):
    return "/faq";
  default:
    return "#"; // Fallback for non-navigable links
  }
};

onMounted(() => {
  checkScreenSize();
  window.addEventListener("resize", checkScreenSize);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenSize);
});
</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" :navigateTo="authenticationNavigate" @update:visible="modalAuthenticationVisible = $event"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <footer class="footer-container">
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
    <div class="mini-subcomponent">
      <div class="top-left-text">{{ t('footer.socialNetworks') }}</div>
      <div class="social-links">
        <a href="https://www.instagram.com/resumify_ai/" target="_blank" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
        <a v-if="!screenSizeSmall" href="https://www.linkedin.com/company/b-ai-solutions-ug/" target="_blank" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
      </div>
    </div>
    <div class="bigger-subcomponent">
      <div class="main-titles">
        <div v-for="(title, index) in mainTitles" :key="index" class="title">
          <span class="title-text">{{ title.name }}</span>
          <div class="sub-titles">
            <template v-for="(subTitle, subIndex) in title.subTitles" :key="subIndex">
              <a
                v-if="subTitle.action"
                :href="getHref(subTitle.text)"
                class="sub-title-link"
                v-bind:class="{'has-link': subTitle.action}"
                role="link"
                :aria-label="subTitle.text"
                @click.prevent="subTitle.action">
                <i v-if="title.name === t('footer.contact') && subTitle.text.includes('Address')" class="fas fa-home"></i>
                <i v-else-if="title.name === t('footer.contact') && subTitle.text.includes('@')" class="fas fa-envelope"></i>
                {{ subTitle.text }}
              </a>
              <span v-else class="sub-title-text">
                <i v-if="title.name === t('footer.contact') && (subTitle.text.includes('Address') || subTitle.text.includes('Adresse') || subTitle.text.includes('Adres'))" class="fas fa-home"></i>
                <i v-else-if="title.name === t('footer.contact') && subTitle.text.includes('@')" class="fas fa-envelope"></i>
                {{ subTitle.text }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="below-middle-text">&copy; 2024 Copyright www.resumify-ai.com</div>
  </footer>
</template>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.social-links a i {
  font-size: 20px;
  display: inline-block;
  margin-right: 10px;
}

.below-middle-text {
  text-align: center;
  padding: 20px 0;
  background-color: #262626;
  color: white;
}

.footer-container {
  margin-top: auto;
  width: 100%;
  background-color: #333;
  color: white;
}

.mini-subcomponent {
  display: flex;
  justify-content: space-between;
  background-color: #5438da;
  color: white;
  padding: 10px 30px 10px 20px;
}

.mini-subcomponent .social-links a {
  margin-left: 20px;
  padding-left: 10px;
  color: white;
}

.bigger-subcomponent {
  background-color: #2b2b2b;
  padding: 20px;
}

.bigger-subcomponent .main-titles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bigger-subcomponent .main-titles .title {
  flex: 1;
  margin-right: 30px;
  min-width: 160px;
  position: relative;
  text-align: left;
}

.bigger-subcomponent .main-titles .title .title-text {
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  text-align: left;
}

.bigger-subcomponent .main-titles .title .title-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 2px;
  background-color: white;
}

.bigger-subcomponent .main-titles .title .sub-titles {
  padding-left: 0;
  text-align: left;
  margin-top: 5px;
  flex-direction: column;
}

.bigger-subcomponent .main-titles .title .sub-titles a {
  text-decoration: none;
  display: block;
  color: white;
  text-decoration: none;
  margin-top: 5px;
  padding-left: 0;
  padding-top: 15px;
  cursor: pointer;
}

.bigger-subcomponent .main-titles .title .sub-titles a.has-link:hover {
  text-decoration: underline;
}

.bigger-subcomponent .main-titles .title:last-child {
  margin-right: 0;
}

.bigger-subcomponent .main-titles .title .sub-titles .sub-title-link,
.bigger-subcomponent .main-titles .title .sub-titles .sub-title-text {
  display: block;
  margin-bottom: 5px;
  padding-top: 15px;
}

</style>
