<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import { useI18n } from "vue-i18n";

import SlidedStep from "./components/SlidedStep.vue";
import * as Images from "./images";

const { locale, t } = useI18n();
const targetElement = ref<HTMLElement | null>(null);
const nextSection = ref<HTMLElement | null>(null);

interface StepData {
  images: string[];
  title: string;
  description: string;
  showSlides: boolean;
}

const stepsDataByLocale: Record<"en" | "de" | "tr", StepData[]>  = {
  en: [
    {
      images: [Images.Step1ImageEn],
      title: "Create your resume",
      description: "And begin your journey to get your dream job.",
      showSlides: false,
    },
    {
      images: [
        Images.TemplateBoldImage,
        Images.TemplateFocusImage,
        Images.TemplateLuxeImage,
        Images.TemplateMinimalImage,
        Images.TemplateModernImage,
        Images.TemplateNeatImage,
        Images.TemplatePureImage,
        Images.TemplateSleekImage,
      ],
      title: "Personalize",
      description: "Select from one of our ATS-friendly, personalised templates. ps. you can also change the color, text font and text size!",
      showSlides: true,
    },
    {
      images: [ Images.Step3ImageEn],
      title: "Fill in your details",
      description: "Our step by step guide will guide you through, so you won't have to worry about how to structure your resume.",
      showSlides: false,
    },
    {
      images: [ Images.Step4ImageEn],
      title: "Evaluate your resume",
      description: "Our evaluation feature uses AI to analyze and score each section of your resume based on the specific job title you're targeting, giving detailed feedback and personalized recommendations",
      showSlides: false,
    },
    {
      images: [ Images.Step5ImageEn,
        Images.Step5ImageEn2,
      ],
      title: "Enhance your resume",
      description: "Optimize your resume with our AI-driven enhancement feature, which tailors your content based on the specific job title you are targeting, ensuring it aligns with industry standards and employer expectations for that role.",
      showSlides: false,
    },
    {
      images: [ Images.Step6ImageEn],
      title: "Download your perfect resume",
      description: "Download every resume you created everytime you need it.",
      showSlides: false,
    },
    {
      images: [ "https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif"],
      title: "Yay! You're all set!",
      description: "Congratz! That's it! You are ready to secure your dream job with your ATS friendly, personalised, enhanced resume.",
      showSlides: false,
    },
  ],
  de: [
    {
      images: [Images.Step1ImageDe],
      title: "Erstellen Sie Ihren Lebenslauf",
      description: "Und beginnen Sie Ihre Reise, um Ihren Traumjob zu bekommen.",
      showSlides: false,
    },
    {
      images: [
        Images.TemplateBoldImage,
        Images.TemplateFocusImage,
        Images.TemplateLuxeImage,
        Images.TemplateMinimalImage,
        Images.TemplateModernImage,
        Images.TemplateNeatImage,
        Images.TemplatePureImage,
        Images.TemplateSleekImage,
      ],
      title: "Personalisieren",
      description: "Wählen Sie eine unserer ATS-freundlichen, personalisierten Vorlagen aus. PS: Sie können auch die Farbe, Schriftart und Schriftgröße ändern!",
      showSlides: true,
    },
    {
      images: [Images.Step3ImageDe],
      title: "Füllen Sie Ihre Daten aus",
      description: "Unsere Schritt-für-Schritt-Anleitung führt Sie durch den Prozess, damit Sie sich keine Sorgen über die Struktur Ihres Lebenslaufs machen müssen.",
      showSlides: false,
    },
    {
      images: [Images.Step4ImageDe],
      title: "Bewerten Sie Ihren Lebenslauf",
      description: "Unsere Bewertungsfunktion nutzt KI, um jeden Abschnitt Ihres Lebenslaufs zu analysieren und basierend auf der spezifischen Berufsbezeichnung, die Sie anstreben, zu bewerten. Dabei erhalten Sie detailliertes Feedback und personalisierte Empfehlungen.",
      showSlides: false,
    },
    {
      images: [Images.Step5ImageDe,Images.Step5ImageEn2],
      title: "Verbessern Sie Ihren Lebenslauf",
      description: "Optimieren Sie Ihren Lebenslauf mit unserer KI-gesteuerten Optimierungsfunktion, die Ihren Inhalt auf die spezifische Berufsbezeichnung abstimmt, die Sie anstreben, und sicherstellt, dass er den Branchenstandards und den Erwartungen des Arbeitgebers für diese Rolle entspricht.",
      showSlides: false,
    },
    {
      images: [Images.Step6ImageDe],
      title: "Laden Sie Ihren perfekten Lebenslauf herunter",
      description: "Laden Sie jeden Lebenslauf, den Sie erstellt haben, jederzeit herunter, wenn Sie ihn benötigen.",
      showSlides: false,
    },
    {
      images: ["https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif"],
      title: "Yay! Sie sind fertig!",
      description: "Herzlichen Glückwunsch! Das war's! Sie sind bereit, Ihren Traumjob mit Ihrem ATS-freundlichen, personalisierten und optimierten Lebenslauf zu sichern.",
      showSlides: false,
    },
  ],

  tr: [
    {
      images: [Images.Step1ImageTr],
      title: "CV'nizi oluşturun",
      description: "Ve hayalinizdeki işe ulaşmak için yolculuğunuza başlayın.",
      showSlides: false,
    },
    {
      images: [
        Images.TemplateBoldImage,
        Images.TemplateFocusImage,
        Images.TemplateLuxeImage,
        Images.TemplateMinimalImage,
        Images.TemplateModernImage,
        Images.TemplateNeatImage,
        Images.TemplatePureImage,
        Images.TemplateSleekImage,
      ],
      title: "Kişiselleştirin",
      description: "ATS uyumlu ve kişiselleştirilmiş şablonlarımızdan birini seçin. Not: Renkleri, yazı tipini ve yazı boyutunu da değiştirebilirsiniz!",
      showSlides: true,
    },
    {
      images: [Images.Step3ImageTr],
      title: "Bilgilerinizi doldurun",
      description: "Adım adım rehberimiz sizi süreç boyunca yönlendirecek, böylece CV'nizin yapısını nasıl oluşturacağınız konusunda endişelenmenize gerek kalmayacak.",
      showSlides: false,
    },
    {
      images: [Images.Step4ImageTr],
      title: "CV'nizi değerlendirin",
      description: "Değerlendirme özelliğimiz, CV'nizin her bölümünü hedeflediğiniz belirli iş unvanına göre analiz etmek ve puanlamak için yapay zeka kullanır. Detaylı geri bildirim ve kişiselleştirilmiş öneriler sunar.",
      showSlides: false,
    },
    {
      images: [Images.Step5ImageTr,Images.Step5ImageEn2],
      title: "CV'nizi geliştirin",
      description: "Hedeflediğiniz belirli iş unvanına göre içeriğinizi uyarlayan ve bu rol için sektör standartlarına ve işveren beklentilerine uygun olmasını sağlayan yapay zeka destekli optimizasyon özelliğimizle CV'nizi optimize edin.",
      showSlides: false,
    },
    {
      images: [Images.Step6ImageTr],
      title: "Mükemmel CV'nizi indirin",
      description: "Oluşturduğunuz tüm CV'leri ihtiyacınız olduğunda her zaman indirin.",
      showSlides: false,
    },
    {
      images: ["https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif"],
      title: "Tebrikler! Her şey hazır!",
      description: "Tebrikler! İşte bu kadar! ATS uyumlu, kişiselleştirilmiş ve geliştirilmiş CV'nizle hayalinizdeki işi almaya hazırsınız.",
      showSlides: false,
    },
  ],

};

const stepsData = computed(() => {
  return stepsDataByLocale[locale.value as "en" | "de" | "tr"] || stepsDataByLocale.en;
});

const followOnInstagram = () => {
  window.open("https://www.instagram.com/resumify_ai/", "_blank"); // Opens in a new tab
};

const scrollToTarget = async () => {
  await nextTick();
  if (targetElement.value) {
    targetElement.value.scrollIntoView({ behavior: "smooth", block: "start" });

    setTimeout(() => {

      window.scrollBy({ top: -400, behavior: "smooth" });
    }, 400);
  }
};
const getStarted = () => {
  if (nextSection.value) {
    nextSection.value.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToMobileEvaluation = () => {
  const mobileEvaluations = document.querySelectorAll(".evaluation-div");
  if (mobileEvaluations.length > 0) {
    mobileEvaluations[0].scrollIntoView({ behavior: "smooth" });
  } else {
    console.error("No .evaluation-div elements found!");
  }
};

</script>

<template>
  <div>
    <div class="middle-card">
      <h3 class="text"><span style="color: #5438da; font-weight: 700;">Resumify AI </span> {{ t('IntroText') }}</h3>
      <div style="margin: 5px;">
        <button class="button" style="background-color: #5438da; color: #ffffff; margin-right: 10px;" @click="scrollToMobileEvaluation">{{ t('GetFeedbackResume') }}</button>
        <button class="button" @click="getStarted">{{ t('Discover') }}</button>
      </div>
      <div class="text" style="font-weight: 100;">{{ t('InfoMobileText') }}</div>
    </div>
    <div ref="nextSection" class="next-section"></div>
    <SlidedStep
      v-for="(step, index) in stepsData"
      :key="index"
      :images="step.images"
      :stepNumber="index + 1"
      :title="step.title"
      :showSlides="step.showSlides"
      :description="step.description"
      :showEvaluation="index=== 3 ? true : false"
      @scrollToTarget="scrollToTarget"
    />
    <div class="follow-div">
      <span ref="targetElement" class="stay-tuned-text"> {{ t('DesktopTryout') }} <span style="font-weight: 700;"> {{ t('Desktop') }}</span> </span>

      <span class="stay-tuned-text2">
        {{ t('StayTunedInstagram') }}
      </span>

      <button
        class="follow-button-mobile"
        @click="followOnInstagram"
      >
        <span>{{ t('Follow') }}</span>
      </button>
    </div>

  </div>
</template>

    <style scoped>

.follow-div{
  display: flex;
 padding: 20px;
 flex-direction: column;
 align-items: center;
}

.stay-tuned-text {
  color: #000000;
  font-size: 24px;
  font-family: "Montserrat";
  line-height: 31px;
  text-align: center;
  margin-bottom: 40px;
}

.stay-tuned-text2 {
  color: #000000;
  font-size: 24px;
  font-family: "Montserrat";
  line-height: 31px;
  text-align: center;
}

.follow-button-mobile{
    cursor: pointer;
  width: 143px;
  height: 48px;
  padding: 0px 8px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 24px;
  outline: none;
  margin-left: 0;
  margin-top: 40px;
  margin-bottom: 70px;
}
.text{
  font-size: 16px;
  font-weight: 700;
  padding-top: 20px;
}

.middle-card {
  width:90%;
  margin-left: 5%;
  margin-right: 5%;
  min-height: 350px;
  background-color: #ffffff;
  box-shadow: 0 0 12px rgba(3,3,3,0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
}

.button {
  cursor: pointer;
  min-width: 70px;
  min-height: 48px;
  padding: 0px 8px;
  border: 2px solid #5856d6;
  border-radius: 8px;
  background-color: transparent;
  color: #5856d6;
  font-size: 10px;
  line-height: 24px;
  outline: none;
}
    </style>
