<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const nextSection = ref<HTMLElement | null>(null);

const getStarted = () => {
  if (nextSection.value) {
    nextSection.value.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<template>
  <div class="image-container">
    <img
      class="padded-image"
      :src="require('@/assets/homeImage.webp')"
      :srcset="
        require('@/assets/homeImage-640.webp') + ' 640w,' +
          require('@/assets/homeImage.webp') + ' 1080w,'
      "
      sizes="(max-width: 640px) 640px,
         (max-width: 1080px) 1080px"
      alt="Unlock your career potential"
      loading="eager"
      fetchpriority="high"
      width="1080"
      height="720"
    />
    <div class="middle-card">
      <h1 class="text">{{ t('unlockYourCareer') }}</h1>
      <button class="button" @click="getStarted">{{ t('getStarted') }}</button>
    </div>
  </div>
  <div ref="nextSection" class="next-section"></div>
</template>

<style scoped>
.image-container {
  width: 100vw;
  height: 650px;
  position: relative;
  padding-bottom: 150px;
}

.padded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.middle-card {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 559px;
  height: 270px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #4f4f4f;
  box-shadow: 0 0 12px rgba(3, 3, 3, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 80px;
  padding-bottom: 50px;
}

.button {
  cursor: pointer;
  width: 171px;
  min-height: 48px;
  padding: 0px 8px;
  border: 2px solid #5856d6;
  border-radius: 8px;
  background-color: transparent;
  color: #5856d6;
  font-size: 18px;
  line-height: 24px;
  outline: none;
}

.button:hover {
  background-color: #5856d6;
  color: white;
}
</style>
