<script setup lang="ts">
import { onMounted } from "vue";

import { useI18n } from "vue-i18n";

import { constructLangPrefix } from "../utils/pathAdjuster";
import HomeView from "../views/home-view/HomeView.vue";

const { t } = useI18n();

onMounted(() => {
  const lang = constructLangPrefix();

  const structuredData = [
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": `https://www.resumify-ai.com${lang}`,
      "name": t("seo.title"),
      "inLanguage": lang,
      "potentialAction": {
        "@type": "SearchAction",
        "target": `https://www.resumify-ai.com${lang}/?s={search_term_string}`,
        "query-input": "required name=search_term_string",
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": t("seo.organizationName"),
      "url": `https://www.resumify-ai.com${lang}/`,
      "sameAs": [
        "https://instagram.com/resumify_ai",
        "https://www.linkedin.com/company/b-ai-solutions-ug/",
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": t("seo.productName"),
      "description": t("seo.productDescription"),
      "brand": t("seo.brand"),
      "offers": [
        {
          "@type": "Offer",
          "priceCurrency": t("seo.productCurrency"),
          "price":  t("seo.productPriceFourMonths"),
          "availability": "https://schema.org/InStock",
          "url": `https://www.resumify-ai.com${lang}/pricing`,
        },
        {
          "@type": "Offer",
          "priceCurrency": t("seo.productCurrency"),
          "price": t("seo.productPriceFourMonths"),
          "availability": "https://schema.org/InStock",
          "url": `https://www.resumify-ai.com${lang}/pricing`,
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": t("breadcrumbs.Home"),
          "item": `https://www.resumify-ai.com${lang}/`,
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": t("breadcrumbs.CvCreation"),
          "item": `https://www.resumify-ai.com${lang}/cv-creation`,
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": t("breadcrumbs.CvEvaluation"),
          "item": `https://www.resumify-ai.com${lang}/cv-evaluation`,
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": t("breadcrumbs.ImportCV"),
          "item": `https://www.resumify-ai.com${lang}/cv-existing`,
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": t("breadcrumbs.Library"),
          "item": `https://www.resumify-ai.com${lang}/library`,
        },
        {
          "@type": "ListItem",
          "position": 6,
          "name": t("breadcrumbs.FAQ"),
          "item": `https://www.resumify-ai.com${lang}/faq`,
        },
      ],
    },
  ];

  const structuredDataScript = document.createElement("script");
  structuredDataScript.type = "application/ld+json";
  structuredDataScript.textContent = JSON.stringify(structuredData);
  document.head.appendChild(structuredDataScript);

  const faqStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": t("faqs.serviceOffered"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.serviceOfferedAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.howToUpgrade"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.howToUpgradeAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.paymentMethods"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.paymentMethodsAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.cancelSubscription"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.cancelSubscriptionAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.premiumTemplates"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.premiumTemplatesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.accountToUse"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.accountToUseAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.techSupport"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.techSupportAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.useMultipleDevices"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.useMultipleDevicesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.dataPrivacy"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.dataPrivacyAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.editAfterCreate"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.editAfterCreateAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.downloadResume"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.downloadResumeAnswer"),
        },
      },
    ],
  };

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.textContent = JSON.stringify(faqStructuredData);
  document.head.appendChild(script);
});
</script>

<template>

  <HomeView />
</template>
