<template>
  <div id="app">
    <Header v-if="route.name !== 'Password'" />
    <router-view />
    <Footer v-if="route.name !== 'Password'" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { locale } = useI18n();
import Footer from "./components/ResumifyFooter.vue";
import Header from "./components/ResumifyHeader.vue";

const route = useRoute();

const allCookiesSelected = ref(false);
const necessaryCookiesSelected = ref(false);

onMounted(() => {
  document.documentElement.setAttribute("lang", "en");
  const loadCookieScript = (language = "en") => {
    // Remove existing script
    const existingScript = document.querySelector("script[src*=\"cookieconsent-v2\"]");
    if (existingScript) {
      return;
    }

    // Inject custom styles for the cookie popup
    const style = document.createElement("style");
    style.innerHTML = `
      .cookie-popup {
        width: 50% !important;
        height: auto !important;
        margin: 0 auto;
        transform: translate(-50%, 0);
        position: fixed;
        left: 50%;
        bottom: 10%;
        padding: 10px !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
        font-size: 0.8em !important;
      }
      .cookie-popup button {
        font-size: 0.9em !important;
        padding: 8px 12px !important;
      }
    `;
    document.head.appendChild(style);

    // Add the cookie consent script
    const script = document.createElement("script");
    script.src =
      "https://app.cockpit.legal/static/cookieconsent-v2.js?tools=Google%20Analytics,Google%20Tag%20Manager,Amazon%20Web%20Services,Facebook,Fontawesome,Google,Klarna,LinkedIn,PayPal";
    script.setAttribute("data-cc-tools", "Google Analytics,Google Tag Manager,Amazon Web Services,Facebook,Fontawesome,Google,Klarna,LinkedIn,PayPal");
    script.setAttribute("data-cc-privacy", "https://resumify-ai.com/policies/privacy-policy");
    script.setAttribute("data-cc-imprint", "https://resumify-ai.com/policies/imprint");
    script.setAttribute("data-cc-color", "#5438da");
    script.setAttribute("data-cc-theme", "browser");
    script.setAttribute("data-cc-language", language);
    script.setAttribute("data-cc-non-eu-consent", "true");

    window.addEventListener("cookieConsentChanged", (event) => {
      const consentedTools = (event as CustomEvent).detail.consentedTools;

      // Check if all cookies are selected
      allCookiesSelected.value = consentedTools.length > 2;

      // Check if only necessary cookies are selected
      necessaryCookiesSelected.value = consentedTools.includes("necessary");
    });

    document.body.appendChild(script);
  };

  // Load script initially based on the current locale
  setTimeout(() => {
    loadCookieScript(locale.value);
  }, 3000);

  // Watch for changes in locale and reload the cookie script
  watch(locale, (newLocale = "en") => {
    loadCookieScript(newLocale);
    document.documentElement.setAttribute("lang", newLocale);
  });
});
</script>

<style>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

}

#app {
  font-family:  'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
