<script setup lang="ts">
import { ref, computed } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const items = ref([
  { textKey: "testimonials.feedback1", subtextKey: "testimonials.feedback1Subtext" },
  { textKey: "testimonials.feedback2", subtextKey: "testimonials.feedback2Subtext" },
  { textKey: "testimonials.feedback3", subtextKey: "testimonials.feedback3Subtext" },
  { textKey: "testimonials.feedback4", subtextKey: "testimonials.feedback4Subtext" },
  { textKey: "testimonials.feedback5", subtextKey: "testimonials.feedback5Subtext" },
  { textKey: "testimonials.feedback6", subtextKey: "testimonials.feedback6Subtext" },
  { textKey: "testimonials.feedback7", subtextKey: "testimonials.feedback7Subtext" },
  { textKey: "testimonials.feedback8", subtextKey: "testimonials.feedback8Subtext" },
  { textKey: "testimonials.feedback9", subtextKey: "testimonials.feedback9Subtext" },
  { textKey: "testimonials.feedback10", subtextKey: "testimonials.feedback10Subtext" },
  { textKey: "testimonials.feedback11", subtextKey: "testimonials.feedback11Subtext" },
  { textKey: "testimonials.feedback12", subtextKey: "testimonials.feedback12Subtext" },
  { textKey: "testimonials.feedback13", subtextKey: "testimonials.feedback13Subtext" },
  { textKey: "testimonials.feedback14", subtextKey: "testimonials.feedback14Subtext" },
  { textKey: "testimonials.feedback15", subtextKey: "testimonials.feedback15Subtext" },
]);

const currentIndex = ref(0);

const visibleItems = computed(() => {
  const totalItems = items.value.length;
  const startIndex = currentIndex.value % totalItems;
  const endIndex = startIndex + 3;
  if (endIndex > totalItems) {
    return [
      ...items.value.slice(startIndex, totalItems),
      ...items.value.slice(0, endIndex % totalItems),
    ];
  } else {
    return items.value.slice(startIndex, endIndex);
  }
});

const scrollLeft = () => {
  if (currentIndex.value === 0) {
    currentIndex.value = items.value.length - 1;
  } else {
    currentIndex.value--;
  }
};

const scrollRight = () => {
  currentIndex.value = (currentIndex.value + 1) % items.value.length;
};
</script>

<template>
  <div class="cards-container">
    <button class="icon-button icon-left" aria-label="next-feedback"  @click="scrollLeft">
      <svg viewBox="0 0 24 24" class="icon">
        <path d="M17.77 20.23 16 22 6 12 16 2l1.77 1.77L9.54 12z"></path>
      </svg>
    </button>
    <div class="cards">
      <div
        v-for="(item, index) in visibleItems"
        :key="index"
        class="card">
        <p class="card-text">{{ t(item.textKey) }}</p>
        <p class="card-subtext">{{ t(item.subtextKey) }}</p>
      </div>
    </div>
    <button class="icon-button icon-right" aria-label="recent-feedback" @click="scrollRight">
      <svg viewBox="0 0 24 24" class="icon">
        <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
      </svg>
    </button>
  </div>
</template>

<style scoped>

.cards{
    display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 85%;
  position: relative;
  padding: 10px;
  padding-left: 10px;
}
.cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 30px;
  padding-bottom: 90px;
}

.card{
    top: 1582px;
    left: 138px;
    width: 350px;
    height: 350px;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-left{
    color: #909090;
    fill: #909090;
    font-size: 40px;
    top: 1684px;
    left: 32px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}

.icon-right{
    color: #909090;
    fill: #909090;
    font-size: 40px;
    top: 1669px;
    left: 1356px;
    width: 40px;
    height: 40px;
    right: 0;
    border: none;
    background-color: transparent;
}

.card-text {
  font-size: 20px;
  margin-bottom: 10px;
  height: 220px;
}

.card-subtext {
  font-size: 16px;
  font-weight: 700;

}

</style>