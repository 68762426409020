import type { ComponentPublicInstance } from "vue";
import { createApp } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser as farUser, faCopy as farCopy } from "@fortawesome/free-regular-svg-icons";
import { faPlus,  faTrash, faMinus, faEdit, faUser, faWandMagicSparkles, faChevronRight, faChevronLeft, faFileCirclePlus, faCheck, faDownload, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PrimeVue from "primevue/config";

import { useAnalytics } from "@/composables/useAnalytics";

import App from "./App.vue";
import i18n from "./languages/i18n";
import router from "./router";
import store from "./store";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { constructLocalizedPath, constructLangPrefix } from "./utils/pathAdjuster";
import "./auth/firebaseInit";

library.add(faPlus, faTrash, faMinus, faEdit, faUser, faWandMagicSparkles, faChevronRight, faChevronLeft, faFileCirclePlus, farUser, faCheck, farCopy, faDownload, faArrowLeft);

const app = createApp(App);

app.use(PrimeVue);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store);
app.use(i18n);

const { trackEvent } = useAnalytics();

router.afterEach((to) => {
  document.documentElement.setAttribute("lang", i18n.global.locale.value);

  const langPrefix = constructLangPrefix();
  const localizedPath = `${langPrefix}${to.path.replace(/^\/(tr|de)(\/|$)/, "/")}`;

  trackEvent("page_view", {
    category: "navigation",
    label: localizedPath,
    locale: i18n.global.locale.value,
  });
});

app.config.errorHandler = (err: unknown, _vm: ComponentPublicInstance | null, info: string) => {
  console.error("Error:", err, "\nInfo:", info);

  if (err instanceof Error) {
    console.error("Error message:", err.message);
  }

  router.push(constructLocalizedPath("/error"));
};

app.use(router).mount("#app");

