<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

const { t } = useI18n();
import { constructLangPrefix } from "../../utils/pathAdjuster";

const props = defineProps<{
  faqs: {
    questionKey: string;
    answerKey: string;
  }[]
}>();
const localizedFaqPath =  constructLangPrefix() + "/faq";

// Local state to track expanded answers
const expandedIndexes = ref<number[]>([]);

const toggleAnswer = (index: number) => {
  const i = expandedIndexes.value.indexOf(index);
  if (i > -1) {
    // Collapse the answer
    expandedIndexes.value.splice(i, 1);
  } else {
    // Expand the answer
    expandedIndexes.value.push(index);
  }
};
</script>

<template>
  <div class="faq-container">
    <h2 class="main-text">{{ t('faqs.title') }}</h2>
    <div class="question-container">
      <div v-for="(faq, index) in props.faqs" :key="index" class="faq-item">
        <div class="question-and-icon" @click="toggleAnswer(index)">
          <h3 class="question">{{ t(faq.questionKey) }}</h3>
          <button class="icon-button" :aria-label="t('faqs.toggleAnswer')">
            <svg viewBox="0 0 24 24" class="icon">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
          </button>
        </div>
        <h3 v-if="expandedIndexes.includes(index)" class="answer">{{ t(faq.answerKey) }}</h3>
      </div>
      <div class="additional-question">
        <h3 class="additional-question-text">{{ t('faqs.additionalQuestion') }}</h3>
        <a :href="localizedFaqPath" class="faq-link-button" title="Read more FAQs">
          {{ t('faqs.faqLinkText') }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faq-container {
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.question-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 60%;
  margin: auto;
}

.main-text {
  color: #030303;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}

.question-and-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.question {
  color: #030303;
  font-size: 24px;
  line-height: 31px;
  margin: 0;
  flex-grow: 1;
  text-align: left;
  border-bottom: 1px solid #c2c2c2;
  font-weight: 100;
}

.icon-button {
  color: #aa93f3;
  fill: #aa93f3;
  font-size: 40px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.answer {
  width: 100%;
  color: #030303;
  font-size: 20px;
  line-height: 31px;
  margin-top: 10px;
  text-align: left;
  padding-left: 0px;
  font-weight: 100;
}

.icon {
  fill: currentColor;
  }

  .additional-question {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin:0;
  width: 100%;
}

.additional-question-text {
  color: #030303;
  margin: 0;
  font-size: 18px;
}

.faq-link-button {
  color: #5438da;
  font-size: 18px;
  padding: 10px 20px;
  text-decoration: none;
  border: none
}
</style>